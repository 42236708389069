<template>
  <div class="card">
    <div class="card-header border-0 d-flex">
      <h3 class="mb-0 text-left">Third Party Data (Rider Profile)</h3>
      <div class="d-flex flex-column ml-auto">
        <div class="mb-2 d-flex">
          <span>Total Participants: {{ participants_request }}</span>
        </div>

        <div class="mb-2 d-flex">
          <span class="text-primary mr-3">Submitted: {{ submitted_request }}</span>
          <span class="text-success">Approved: {{ approved_request }}</span>
        </div>

        <div class="mb-2 d-flex">
          <span class="text-danger mr-3">Rejected: {{ rejected_request }}</span>
          <span class="text-danger" style="display: none">Removed: {{ removed_request }}</span>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form class="form-inlin" @submit.prevent="searchHandler">
        <div class="row">
          <div class="form-group col-3">
            <input id="id" v-model="form.search_id" class="form-control" placeholder="enter player id" type="text"/>
          </div>
          <div class="form-group col-2">
            <select v-model="form.search_platform" class="form-control" name="search_platform">
              <option value="">Select Platform</option>
              <option value="Strava">Strava</option>
              <option value="Zwift">Zwift</option>
              <option value="Trainer road">Trainer road</option>
              <option value="Rouvy">Rouvy</option>
              <option value="Bkool">Bkool</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div class="form-group col-2">
            <select v-model="form.search_status" class="form-control" name="search_status">
              <option value="">Select Status</option>
              <option value="submitted">submitted</option>
              <option value="approved">approved</option>
              <option value="rejected">rejected</option>
              <!--              <option value="removed">removed</option>-->
            </select>
          </div>
          <div class="form-group col-2" style="display:none;">
            <input v-model="form.search_date" :max="today" class="form-control" type="date"/>
          </div>
          <div class="col-2">
            <button class="btn btn-primary mb-2" type="submit">Search</button>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table align-items-center table-flush">
          <thead class="thead-light">
          <tr class="text-left">
            <th scope="col">Player ID</th>
            <th scope="col">Migration Platform</th>
            <th scope="col">Status</th>
            <th scope="col">Submitted On</th>
            <th scope="col">Action</th>
          </tr>
          </thead>
          <tbody class="list">
          <template v-if="third_party_data && third_party_data.data && third_party_data.data.length">
            <tr v-for="player in third_party_data.data" :key="player._id" class="text-left">
              <td>{{ player.user_id }}</td>
              <td>{{ player.migration_platform }}</td>
              <td>{{ player.status }}</td>
              <td>{{
                  new Date(player.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                }}
              </td>
              <td>
                <div aria-label="Basic example" class="btn-group" role="group">
                  <router-link :to="{ name: 'ThirdPartyDetail', params: { id: player._id }}"
                               class="btn btn-sm btn-outline-primary">
                    View Record
                  </router-link>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5">No record available at this moment.</td>
          </tr>
          </tbody>
        </table>
      </div>


      <div class="mx-auto">
        <pagination v-model="page" :per-page="perPage" :records="total" theme="bootstrap4" @paginate="fetchPlayers"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from 'vue-pagination-2';

export default {
  name: 'ThirdPartyList',
  data() {
    return {
      page: 1,
      participants_request: 0,
      submitted_request: 0,
      rejected_request: 0,
      approved_request: 0,
      removed_request: 0,
      form: {
        search_id: null,
        search_platform: "",
        search_status: "",
        search_date: "",
      }
    }
  },

  components: {
    Pagination
  },

  computed: {
    ...mapGetters('player', ['third_party_data']),
    total() {
      if (!this.third_party_data) return 0;
      return this.third_party_data.total;
    },

    perPage() {
      if (!this.third_party_data) return 0;
      return parseInt(this.third_party_data.per_page);
    },
    today() {
      return new Date().toISOString().split('T')[0];
    }
  },
  created() {
    this.fetchPlayers();
  },
  methods: {
    // Convert the form object to a query string
    toQueryString(obj) {
      const params = new URLSearchParams();
      for (const key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
          params.append(key, obj[key]);
        }
      }
      return params.toString();
    },
    async fetchPlayers(page = 1) {
      this.page = page;
      const query = this.toQueryString(this.form);
      const {data} = await this.$store.dispatch('player/get_third_party_records', {
        page: this.page,
        form: query
      });
      // Assuming response has data, total, page, and perPage
      this.participants_request = data.third_party_data.total;
      this.submitted_request = data.submitted_request;
      this.approved_request = data.approved_request;
      this.rejected_request = data.rejected_request;
    },
    // Search handler
    searchHandler() {
      this.page = 1;
      this.fetchPlayers(this.page);
    }
  }
}
</script>

<style scoped>
.VuePagination__count.VuePagination__count {
  color: #2d8968;
}
</style>

